<template>
  <div class="home">
    <a-breadcrumb>
      <a-breadcrumb-item href="">
        <a-icon type="home" />
      </a-breadcrumb-item>
      <a-breadcrumb-item href="">
     <a-icon type="setting" />
        <span>系统</span>
      </a-breadcrumb-item>
      <a-breadcrumb-item> 支付配置 </a-breadcrumb-item>
    </a-breadcrumb>

    <div class="search-list-box baff">
      <a-form-model ref="rulepassForm" :model="data" :rules="infoRules" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }"
       style="width: 600px">
      	<a-row :gutter="24">
      		<a-col :span="24">
      			<a-form-model-item label="微信Appid：" prop="wechat_appid">
      				<a-input v-model="data.wechat_appid" placeholder="请输入微信Appid" />
      			</a-form-model-item>
      		</a-col>
      		<a-col :span="24">
      			<a-form-model-item label="微信Secret：" prop="wechat_secret">
      				<a-input v-model="data.wechat_secret" placeholder="请输入微信Secret"/>
      			</a-form-model-item>
      		</a-col>
      		<a-col :span="24">
      			<a-form-model-item label="微信商户号：" prop="wechat_mchid">
      				<a-input v-model="data.wechat_mchid" placeholder="请输入微信商户号"/>
      			</a-form-model-item>
      		</a-col>
      		<a-col :span="24">
      			<a-form-model-item label="微信商户密钥：" prop="wechat_key">
      				<a-input v-model="data.wechat_key" placeholder="请输入微信商户密钥"/>
      			</a-form-model-item>
      		</a-col>
      		<a-col :span="24">
      			<a-form-model-item label="支付宝应用Appid：" prop="alipay_appid">
      				<a-input v-model="data.alipay_appid" placeholder="请输入支付宝应用Appid" />
      			</a-form-model-item>
      		</a-col>
      		<a-col :span="24">
      			<a-form-model-item label="支付宝私钥：" prop="alipay_privatekey">
					<a-textarea v-model="data.alipay_privatekey" placeholder="请输入支付宝私钥" :rows="4" />
      			</a-form-model-item>
      		</a-col>
      		<a-col :span="24">
      			<a-form-model-item label="支付宝应用公钥：" prop="alipay_publickey">
					<a-textarea v-model="data.alipay_publickey" placeholder="请输入支付宝应用公钥" :rows="4" />
      			</a-form-model-item>
      		</a-col>
      		<a-col :span="24">
      			<a-form-model-item label="是否开启微信：" prop="is_wxpay">
					<a-switch :checked="data.is_wxpay==1?true:false" default-checked @change="changeWx"/>
      			</a-form-model-item>
      		</a-col>
      		<a-col :span="24">
      			<a-form-model-item label="是否开启支付宝：" prop="is_alipay">
					<a-switch :checked="data.is_alipay==1?true:false" default-checked @change="changeAli"/>
      			</a-form-model-item>
      		</a-col>
			<a-col :span="24">
			  <a-button
			      type="primary"
			      class="marginr20"
			      style="margin-left: 300px"
			      html-type="submit"
				  @click="updateCorp"
			  >
			  确定
			</a-button>
			</a-col>
      	</a-row>
      </a-form-model>
    </div>
  </div>
</template>

<script>
	import http from '../http'
export default {
  data() {
    return {
      data: {
		data:{
			wechat_appid:'',
			wechat_secret:'',
			wechat_key:'',
			alipay_appid:'',
			alipay_privatekey:'',
			alipay_publickey:'',
			is_wxpay:true,
			is_alipay:true,
		}
	  },
      infoRules: {
      	wechat_appid: [{
      		required: true,
      		message: '请输入微信Appid',
      		trigger: 'blur'
      	}, ],
      	wechat_secret: [{
      		required: true,
      		message: '请输入微信Secret',
      		trigger: 'blur'
      	}, ],
      	wechat_mchid: [{
      		required: true,
      		message: '请输入微信商户号',
      		trigger: 'blur'
      	}, ],
      	wechat_key: [{
      		required: true,
      		message: '请输入微信商户密钥',
      		trigger: 'blur'
      	}, ],
      },
    };
  },
  created () {
	  this.getDetail()
  },
 
  methods: {
	updateCorp() {
		this.$refs.rulepassForm.validate(valid => {
			if (valid) {
				http({
					method: 'post',
					url: '/api.corp.edit/',
					data: this.data
				}).then((res) => {
					if (res.code == '200') {
						this.$message.success('资料修改成功');
					} else {
						this.$message.error(res.message);
					}
				})
			} else {
				this.$message.warning('请检查是否已经输入必填项!');
				return false;
			}
		});
	},
	// 详情
	getDetail() {
		http({
			method: 'post',
			url: '/api.corp.detail/',
			data: {}
		}).then((res) => {
			this.data = res.data
		})
	},
	changeAli(e){
		if( e == false)this.data.is_alipay = 0
		else this.data.is_alipay = 1
	},
	changeWx(e){
		if( e == false)this.data.is_wxpay = 0
		else this.data.is_wxpay = 1
	}
  },
};
</script>
<style  lang="less">
    .baff {
        background-color: #fff;
        margin-top: 16px;
        padding-bottom: 100px;
        padding-top: 50px;
    }
    .nesw {
        margin: 0px;
        font-size: 12px;
        color: red;
        line-height: 16px;
    }
</style>
